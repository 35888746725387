.header_btn {
    padding: 5px;
    background-color: darkorange;
    border-radius: 0px 102px 102px 0px;
    border: 1px solid darkorange;
    margin-right: -6px;
}

.header {
    padding: 10px
}

.logo a {
    text-decoration: none;
}

.card__container a {
    text-decoration: none;
    color: #000;
}

.logo h3 {
    color: rgb(7, 244, 51);

}

.search_icons {
    font-size: 24px;
    color: white;
    width: 30px;
}

.plus_icon {
    font-size: 22px;
    font-weight: 600;
}

.login_btn {
    width: 70px;
    padding: 6px;
    background-color: darkorange;
    border-radius: 102px;
    border: 0px;
    color: white;
    font-weight: 600;
}

.sell_btn {
    width: 100px;
    padding: 6px;
    border-radius: 102px;
    border-top: 5px solid rgb(7, 244, 51);
    border-left: 5px solid darkorange;
    border-right: 5px solid rgb(7, 244, 240);
    font-weight: 700;
    border-bottom: 5px solid rgb(17, 53, 234);
    background-color: #fff;
}

.sell_btn:hover {
    background-color: lightyellow;
}


.sub__header .sell_old {
    width: 180px;
    height: 40px;
    border-radius: 102px;
    font-size: 19px;
    color: white;
    font-weight: 600;
    background-color: darkorange;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border: 1.6px solid darkorange;
    padding: 10px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

@media only screen and (max-width: 1224px) and (min-width: 400px) {
    .sub__header .sell_old {
        width: 140px;
        font-size: 16px;
    }
}

.sub__header .sell_old a {
    text-align: center;
    text-decoration: none;
    color: #fff;
}

.input {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 102px;
    border: 1.6px solid darkorange;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 5px;
}

.input .icon {
    width: 50px;
    font-size: 20px;
    color: darkorange;
}

.input input {
    border: none;
    outline: none;
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .city__search {
        display: none;
    }

    .sub__header .sell_old {
        width: 110px;
        font-size: 12px;
        padding: 0px;
    }
}

.category_btn {
    width: 220px;
    height: 40px;
    border-radius: 10px;
    border: 1.5px solid darkorange;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
}

.category_btn:hover {
    outline: none;
    box-shadow: none;
    border: 1.6px solid darkorange;
}

.dropdown-menu li a {
    font-weight: 550;
    font-size: 17px;
    ;
}

.dropdown ul li {
    margin-left: 30px;
}

.heart_icon {
    font-weight: 900;
    font-size: 20px;
}

.card_img {
    position: relative;
    padding: 10px;

}

.heart_icon .icons {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    width: 25px;
    color: deeppink;

}

.location_row {
    font-size: 17px;
    font-weight: 600;
}

.featured p {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 25px;
    width: 80px;
    background-color: darkorange;
    font-weight: 600;
    padding-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    color: white;

}

.product__card {
    height: 330px;
}

.card_img img {
    height: 180px !important;
    object-fit: cover;
}

.date_row {
    font-size: 17px;
    font-weight: 600;
}

.swiper {
    width: 100%;
    height: 400px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product_detail {
    height: 140px;
    width: 100%;
    border: 0.5px solid #4b6c70;
    border-radius: 10px;
}

.share_icon {
    font-size: 25px;
    margin: 7px;
    color: deepskyblue;
    cursor: pointer;
}

.heart_icon {
    font-size: 30px;
    margin: 7px;
    color: deeppink;
    cursor: pointer;
}

.product_detail .pro_name {
    margin-left: 20px;
    color: #4b6c70;
}

.location_preview {
    color: #4b6c70;
    font-size: 19px;
    font-weight: 600;

}

.date_preview {
    color: #4b6c70;
    font-size: 19px;
    font-weight: 600;
}

.price_preview {
    margin-left: 20px;
}

.btn__options .active {
    color: #000 !important;
    font-weight: bold;
}

.share__options {
    display: flex;
    justify-content: space-around;
}

.modal-body .share__icon {
    font-size: 35px !important;
    cursor: pointer;
}

@media (min-width: 480px) and (max-width: 900px) {
    .category_btn {
        width: 160px;
        font-size: 14px;
    }

    .sub__header .sell_old {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: white;
        font-weight: 400;
        background-color: darkorange;
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid darkorange;
        padding: 7px;
        margin-left: 20px;
    }

    .card__container .last_row p {
        font-size: 10px !important;
    }

    .card__container .card-title {
        font-size: 14px;
        font-weight: bold;
    }

    .featured p {
        position: absolute;
        top: 5px;
        left: 5px;
        height: 20px;
        width: 60px;
        background-color: darkorange;
        border-radius: 5px;
        font-size: 12px;
    }
}