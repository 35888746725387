.sell__type {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.sell__type a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    height: 50px;
    width: 200px;
    border-radius: 100px;
    background-color: darkorange;
    color: #fff !important;
    margin: 20px 0;
    cursor: pointer;
}

.my__color {
    color: darkorange;
    font-weight: 550;
}

.sell__new__container input,
.sell__new__container textarea,
.sell__new__container select,
.sell__new__container input[type='number'] {
    border: 1px solid orange !important;
}