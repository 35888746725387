.footer__container {
    padding: 20px 0;
}

.footer__container ul {
    list-style: none;
}

.footer__container ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.copyright__section {
    background-color: #131a22;
    color: #fff;
    padding: 10px 0px;
}

.follow_us {
    align-items: center;
    justify-content: center;
}

.follow_us h5 {
    margin-right: 10px;
}

.follow_icons {
    margin: 7px;
    font-size: 34px;
    color: white !important;
    ;
    padding: 7px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
}

.follow_icons:hover {
    background-color: black;
    color: black;
}

.copy__right {
    justify-content: space-around;
    display: flex;
    align-items: center;
}

.footer__heading {
    color: #131a22;
    font-weight: 650;
}

@media only screen and (max-width: 480px) {
    .footer__heading {
        font-size: 16px;
        text-align: center;

    }

    .copy__right {
        justify-content: space-around;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .follow_icons {
        margin: 8px;

    }
}